<template>
  <div>
    <div class="row pt-3">
      <div class="col-md p-1"><h5>加工费  <span id="processFee" v-if="groupCenterLine.originalProcessingFee"><i :class="iconClass2" v-on:mouseenter="onEnterId(2)" v-on:mouseleave="onLeaveId(2)"></i></span></h5></div>
      <div class="col-md p-1 text-right">
        <span class="font-weight-bold" style="font-size: 1.6rem">￥{{ Number(groupCenterLine.processFee).toFixed("2") }}元</span>
      </div>
      <b-popover target="processFee" triggers="hover focus" placement="bottom">
        <div class="row pt-2">
          <div class="col-md">
            加工费 = 加工单价 * 订单平米({{setUpPrice.setUpAreaPrice}} * {{conDeail.orderDeailArea}})
            原加工费{{groupCenterLine.originalProcessingFee}}
          </div>
        </div>
      </b-popover>
    </div>
    <div class="row pt-3">
      <div class="col-md p-1"><h5>材料费  <span id="materialFee" v-if="groupCenterLine.rawMaterialCost"><i :class="iconClass1" v-on:mouseenter="onEnterId(1)" v-on:mouseleave="onLeaveId(1)"></i></span></h5></div>
      <div class="col-md p-1 text-right">
        <span class="font-weight-bold" style="font-size: 1.6rem">￥{{ Number(groupCenterLine.materialFee).toFixed("2") }}元 </span>
      </div>
      <b-popover target="materialFee" triggers="hover focus" placement="bottom">
        <div class="row pt-2">
          <div class="col-md">
            材料金额 = 材料平米单价 * (订单平米 / 利用率)({{groupCenterLine.materialMsg}})
            原材料费:{{groupCenterLine.rawMaterialCost}}
          </div>
        </div>
      </b-popover>
    </div>
<!--    <div class="row pt-3">
      <div class="col-md p-1"><h5>指定物料材料费</h5></div>
      <div class="col-md p-1 text-right">
        <span class="font-weight-bold" style="font-size: 1.6rem">￥{{ Number(groupCenterLine.appointMaterialFee).toFixed("2") }}元 </span>
      </div>
    </div>-->
    <div class="row pt-3">
      <div class="col-md p-1"><h5>材料费计算方式：</h5></div>
      <div class="col-md p-1 text-right">
        <span class="font-weight-bold">{{ groupCenterLine.materialMsg }}</span>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-md p-1"><h5>管理费</h5></div>
      <div class="col-md text-right p-1" style="font-size: 0.1rem;">
        <span class="font-weight-bold" style="font-size: 1.6rem">￥{{groupCenterLine.manageFee}}元</span>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-md p-1"><h5>管理费计算方式：</h5></div>
      <div class="col-md p-1 text-right">
        <span class="font-weight-bold">销售金额{{notification.conDeail.computAmount}} * 管理费费率{{managePrecent}}%</span>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-md p-1"><h5>业务费</h5></div>
      <div class="col-md text-right p-1" style="font-size: 0.1rem;">
        <span class="font-weight-bold" style="font-size: 1.6rem">￥{{groupCenterLine.saleFee}}元</span>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-md p-1"><h5>业务费计算方式：</h5></div>
      <div class="col-md p-1 text-right">
        <span class="font-weight-bold">销售金额{{notification.conDeail.computAmount}} * 业务费费率{{salePrecent ? salePrecent : 0}}%</span>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-md p-1"><h5>毛利率</h5></div>
      <div class="col-md p-1 text-right">
        <span class="font-weight-bold" style="font-size: 1.6rem">
          {{groupCenterLine.grossProfitRate}}%</span>
      </div>
    </div>
<!--    <div class="row pt-3">
      <div class="col-md p-1"><h5>毛利率计算方式：</h5></div>
      <div class="col-md p-1 text-right">
        <span class="font-weight-bold">(销售金额{{notification.conDeail.computAmount}} - 成本费用{{notification.productFeeOne}})/ 销售金额{{notification.conDeail.computAmount}}</span>
      </div>
    </div>-->
    <div class="row pt-3">
      <div class="col-md p-1"><h5>净利率</h5></div>
      <div class="col-md p-1 text-right">
        <span class="font-weight-bold" style="font-size: 1.6rem">
          {{groupCenterLine.netProfitRate}}%
        </span>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-md p-1"><h5>成本单价</h5></div>
      <div class="col-md p-1 text-right">
        <span class="font-weight-bold">￥{{(Number(groupCenterLine.costPrice)).toFixed("6") }} 元/PCS</span>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-md p-1"><h5>成本金额</h5></div>
      <div class="col-md p-1 text-right">
        <span class="font-weight-bold">￥{{(Number(groupCenterLine.processFee) + Number(groupCenterLine.materialFee)).toFixed("2") }}元</span>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-md p-1"><h5>龙南单价({{notification.exchangeType?notification.exchangeType:"RMB"}})</h5></div>
      <div class="col-md p-1 text-right">
        <span class="font-weight-bold">{{(Number(groupCenterLine.lnPrice)).toFixed("6") }}</span>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-md p-1"><h5>江西单价</h5></div>
      <div class="col-md p-1 text-right">
        <span class="font-weight-bold">￥{{(Number(groupCenterLine.jxPrice)).toFixed("6") }} 元/PCS</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["groupCenterLine", "notification", "managePrecent", "salePrecent","setUpPrice","conDeail"],
  data() {
    return {
      iconClass1: "fa fa-question-circle alert-link",
      iconClass2: "fa fa-question-circle alert-link",
      iconClass3: "fa fa-question-circle alert-link",
      iconClass4: "fa fa-question-circle alert-link",
      iconClass5: "fa fa-question-circle alert-link",
      iconClass6: "fa fa-question-circle alert-link",
      iconClass7: "fa fa-question-circle alert-link",
      iconClass8: "fa fa-question-circle alert-link",
      iconClass9: "fa fa-question-circle alert-link",
      iconClass10: "fa fa-question-circle alert-link"
    };
  },
  methods:{
    onEnterId(flag) {
      if (flag == 1){
        this.iconClass1 = "fa fa-question-circle alert-success";
      }else if (flag == 2){
        this.iconClass2 = "fa fa-question-circle alert-success";
      }else if (flag == 3){
        this.iconClass3 = "fa fa-question-circle alert-success";
      }else if (flag == 4){
        this.iconClass4 = "fa fa-question-circle alert-success";
      }else if (flag == 5){
        this.iconClass5 = "fa fa-question-circle alert-success";
      }else if (flag == 6){
        this.iconClass6 = "fa fa-question-circle alert-success";
      }else if (flag == 7){
        this.iconClass7 = "fa fa-question-circle alert-success";
      }else if (flag == 8){
        this.iconClass8 = "fa fa-question-circle alert-success";
      }else if (flag == 9){
        this.iconClass9 = "fa fa-question-circle alert-success";
      }else if (flag == 10){
        this.iconClass10 = "fa fa-question-circle alert-success";
      }
    },
    onLeaveId(flag) {
      if (flag == 1){
        this.iconClass1 = "fa fa-question-circle alert-link";
      }else if (flag == 2){
        this.iconClass2 = "fa fa-question-circle alert-link";
      }else if (flag == 3){
        this.iconClass3 = "fa fa-question-circle alert-link";
      }else if (flag == 4){
        this.iconClass4 = "fa fa-question-circle alert-link";
      }else if (flag == 5){
        this.iconClass5 = "fa fa-question-circle alert-link";
      }else if (flag == 6){
        this.iconClass6 = "fa fa-question-circle alert-link";
      }else if (flag == 7){
        this.iconClass7 = "fa fa-question-circle alert-link";
      }else if (flag == 8){
        this.iconClass8 = "fa fa-question-circle alert-link";
      }else if (flag == 9){
        this.iconClass9 = "fa fa-question-circle alert-link";
      }else if (flag == 10){
        this.iconClass10 = "fa fa-question-circle alert-link";
      }
    }
  }
};
</script>
